import Navbartop from "../components/Nabartop";
import Courousel from "../components/Courousel";
import Tabs from "../components/Tabs";
import Firstbanner from "../components/Firstbanner";
import Products from "../components/Products";
import Trendingnow from "../components/Trendingnow";
import Footer from "../components/Footer";


const Homescreen = () => {
  return (
    <div className="bg-red">
      <Navbartop />
      <Courousel />
      <Tabs />
      <Firstbanner />
      <Products />
      <Trendingnow />
      <Footer />
      
    </div>
  );
};

export default Homescreen;
