import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Firstslide from "../img/secondslide.jpeg";

const images = [
  Firstslide,
  "https://source.unsplash.com/random/800x401",
  "https://source.unsplash.com/random/800x402",
  "https://source.unsplash.com/random/800x403",
];

const Courousel = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const previousImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  return (
    <div className="relative">
      <div className="overflow-hidden">
        <img
          src={images[currentImage]}
          alt="carousel"
          className="w-full h-[550px]"
        />
      </div>

      <div className="absolute top-1/2 transform -translate-y-1/2 left-0 right-0 flex justify-between">
        <button
          onClick={previousImage}
          className="bg-[#E8AE3C] hover:bg-[#fff]  hover:text-[#E8AE3C] text-white py-2 px-4 rounded-l"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          onClick={nextImage}
          className="bg-[#E8AE3C] hover:bg-[#fff]  hover:text-[#E8AE3C] text-white py-2 px-4 rounded-r"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Courousel;
