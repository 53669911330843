const Footerbottom = () => {
  return (
    <div className="flex px-7 px7 justify-between mt-[50px]">
      <div>
        <h2>Order or Purchases</h2>

        <ul>
            <li className="text-[14px] text-[#37B6D4]">Orders history</li>
        </ul>
      </div>
      <div>
        <h2>Support & Services</h2>
        <ul>
            <li className="text-[14px] text-[#37B6D4]">Contact Us</li>
        </ul>
      </div>
      <div>
        <h2>Partnerships</h2>
        <ul>
            <li className="text-[14px] text-[#37B6D4]">My account</li>
        </ul>
      </div>
      <div></div>
    </div>
  );
};

export default Footerbottom;
