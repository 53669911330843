import Logo from "../img/logo.png";

const Navbartop = () => {
  return (
    <div className="bg-[#37B6D4] w-full h-120">
      <div className="flex h-24 bg-[#37B6D4] w-full justify-between">
        <div className="flex px-[24px] py-[24px] ">
          <img src={Logo} alt="logo" className="h-[25px] w-[120px]" />

          <div className="flex pl-[40px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-[#fff]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <h2 className="text-[#fff]">Menu</h2>
          </div>

          <div className="pl-[24px] mt-[-5px]">
            <input
              placeholder="Search for books"
              className="rounded-[5px] h-[39px]   md:w-[371px] text-left"
            />
          </div>
        </div>

        <div className="px-[24px] py-[24px] text-[#ffffff]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </div>
      </div>

      <div className="flex justify-between w-full border-t-2 border-t-[#fff]">
        <div>
          <ul className="flex">
            <li className="p-4 text-[12px] text-[#fff]">Books</li>
            <li className="p-4 text-[12px]  text-[#fff]">Gift Items</li>
            <li className="p-4 text-[12px]  text-[#fff]">Stationary</li>
            <li className="p-4 text-[12px] text-[#fff]">About Us</li>
            <li className="p-4 text-[12px] text-[#fff]">Top Deals</li>
          </ul>
        </div>

        <div>
          <ul className="flex">
            <li className="p-4 text-[15px]  text-[#fff] flex">
              Account{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </li>
            <li className="p-4 text-[15px] text-[#fff] flex">
              My List{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </li>
            <li className="p-4 text-[15px] text-[#fff] flex">
              Top Deals{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbartop;
