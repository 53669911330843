import React from "react";
import { Link } from "react-router-dom";
import One from "../img/one.png";
import Two from "../img/two.png";
import Three from "../img/three.png";
import Four from "../img/four.png";
import Five from "../img/five.png";
import Six from "../img/six.png";

const Tabs = () => {
  const [openTab, setOpenTab] = React.useState(1);

  console.log(openTab);
  return (
    <div className="container mx-auto mt-12 px-7 py-7">
      <div className="flex flex-col items-center justify-center w-full">
        <ul className="flex space-x-0 justify-between w-full border-b-4">
          <li className={openTab === 1 ? "border-b-4 border-b-[#E8AE3C]" : ""}>
            <Link
              to="#"
              onClick={() => setOpenTab(1)}
              className={
                openTab === 1
                  ? "text-[#E8AE3C] inline-block px-4 py-2 text-[15px]"
                  : "inline-block px-4 py-2 text-gray-600 bg-white  text-[15px]"
              }
            >
              Gift Items
            </Link>
          </li>
          <li className={openTab === 2 ? "border-b-4 border-b-[#E8AE3C]" : ""}>
            <Link
              to="#"
              onClick={() => setOpenTab(2)}
              className={
                openTab === 2
                  ? "text-[#E8AE3C] inline-block px-4 py-2 text-[15px]"
                  : "inline-block px-4 py-2 text-gray-600 bg-white  text-[15px]"
              }
            >
              Stationary
            </Link>
          </li>
          <li className={openTab === 3 ? "border-b-4 border-b-[#E8AE3C]" : ""}>
            <Link
              to="#"
              onClick={() => setOpenTab(3)}
              className={
                openTab === 3
                  ? "text-[#E8AE3C] inline-block px-4 py-2 text-[15px]"
                  : "inline-block px-4 py-2 text-gray-600 bg-white  text-[15px]"
              }
            >
              Books
            </Link>
          </li>
          <li className={openTab === 4 ? "border-b-4 border-b-[#E8AE3C]" : ""}>
            <Link
              to="#"
              onClick={() => setOpenTab(4)}
              className={
                openTab === 4
                  ? "text-[#E8AE3C] inline-block px-4 py-2 text-[15px]"
                  : "inline-block px-4 py-2 text-gray-600 bg-white  text-[15px]"
              }
            >
              Top Deals
            </Link>
          </li>
          <li className={openTab === 5 ? "border-b-4 border-b-[#E8AE3C]" : ""}>
            <Link
              to="#"
              onClick={() => setOpenTab(5)}
              className={
                openTab === 5
                  ? "text-[#E8AE3C] inline-block px-4 py-2 text-[15px]"
                  : "inline-block px-4 py-2 text-gray-600 bg-white  text-[15px]"
              }
            >
              Trending Now
            </Link>
          </li>
        </ul>
        <div className="p-3 mt-6 bg-white border">
          <div
            className={
              openTab === 1 ? "grid grid-cols-6 w-full gap-4" : "hidden"
            }
          >
            {" "}
            <div>
              <img src={One} alt="slide one" />
            </div>
            <div>
              <img src={Two} alt="slide one" />
            </div>
            <div>
              <img src={Three} alt="slide one" />
            </div>
            <div>
              <img src={Four} alt="slide one" />
            </div>
            <div>
              <img src={Five} alt="slide one" />
            </div>
            <div>
              <img src={Six} alt="slide one" />
            </div>
          </div>
          <div
            className={
              openTab === 2 ? "grid grid-cols-6 w-full gap-4" : "hidden"
            }
          >
            <div>
              <img src={One} alt="slide one" />
            </div>
            <div>
              <img src={Two} alt="slide one" />
            </div>
            <div>
              <img src={Three} alt="slide one" />
            </div>
            <div>
              <img src={Four} alt="slide one" />
            </div>
            <div>
              <img src={Five} alt="slide one" />
            </div>
            <div>
              <img src={Six} alt="slide one" />
            </div>
          </div>
          <div
            className={
              openTab === 3 ? "grid grid-cols-6 w-full gap-4" : "hidden"
            }
          >
            <div>
              <img src={One} alt="slide one" />
            </div>
            <div>
              <img src={Two} alt="slide one" />
            </div>
            <div>
              <img src={Three} alt="slide one" />
            </div>
            <div>
              <img src={Four} alt="slide one" />
            </div>
            <div>
              <img src={Five} alt="slide one" />
            </div>
            <div>
              <img src={Six} alt="slide one" />
            </div>
          </div>


          <div
            className={
              openTab === 4 ? "grid grid-cols-6 w-full gap-4" : "hidden"
            }
          >
            <div>
              <img src={One} alt="slide one" />
            </div>
            <div>
              <img src={Two} alt="slide one" />
            </div>
            <div>
              <img src={Three} alt="slide one" />
            </div>
            <div>
              <img src={Four} alt="slide one" />
            </div>
            <div>
              <img src={Five} alt="slide one" />
            </div>
            <div>
              <img src={Six} alt="slide one" />
            </div>
          </div>


          <div
            className={
              openTab === 5 ? "grid grid-cols-6 w-full gap-4" : "hidden"
            }
          >
            <div>
              <img src={One} alt="slide one" />
            </div>
            <div>
              <img src={Two} alt="slide one" />
            </div>
            <div>
              <img src={Three} alt="slide one" />
            </div>
            <div>
              <img src={Four} alt="slide one" />
            </div>
            <div>
              <img src={Five} alt="slide one" />
            </div>
            <div>
              <img src={Six} alt="slide one" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
