import Trend from "../img/trend.png";

const Trendingnow = () => {
  return (
    <div className="px-7 py-7">
      <div className="border-b-[0.5px] border-b-[#000]">
        <h2 className="font-[500] text-[19px] font-[inter]"> Trending Now</h2>
      </div>
      <div className=" md:flex gap-4 mt-[50px] justify-center w-full px-4 py-4">
        <div className="h-[400px] bg-[#D9D9D9]  w-full">
          <div className="px-[24px] py-[24px]">
            <h2 className="text-[19px]">Books</h2>
          </div>

          <div className="px-7 grid grid-cols-2 w-full gap-4 justify-center">
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Gift clock</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Clock alarm</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Gift clock</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Clock alarm</h2>
            </div>
          </div>
        </div>
        <div className="h-[400px] bg-[#D9D9D9]  w-full">
          <div className="px-[24px] py-[24px]">
            <h2 >Gift Icons</h2>
          </div>

          <div className="px-7 grid grid-cols-2 w-full gap-4 justify-center">
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Gift clock</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Clock alarm</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Gift clock</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Clock alarm</h2>
            </div>
          </div>


        </div>
        <div className="h-[400px] bg-[#D9D9D9]  w-full">
          <div className="px-[24px] py-[24px]">
            <h2 className="text-[19px]"> Stationary</h2>
          </div>
          <div className="px-7 grid grid-cols-2 w-full gap-4 justify-center">
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Gift clock</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Clock alarm</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Gift clock</h2>
            </div>
            <div>
              <img src={Trend} alt="slide one" />
              <h2 className="text-[16px] text-[#000080]">Clock alarm</h2>
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Trendingnow;
